import Parser from "html-react-parser";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import backicon from '../../assets/images/backicon.png';
import moment from "moment";
const { ToWords } = require('to-words');
class ViewAppointment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bookingData: [],
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		const userId = localStorage.getItem("AppointmentId");

		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				catCount: "*",
				whereClause: `where fld_id = ${userId}`,
			},
			"GetBookedAppointments"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						bookingData: obj.data,
					});
					Notiflix.Loading.Remove();
				}
			}))

	}
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* <Breadcrumbs breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')} breadcrumbItem1={"View Order"} urlPath={"/order_list"} urlPath1={"/view-order"} /> */}
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
									</div>
								</Card>
							</Col>
							<Col>
								<div className=" d-flex justify-content-between"><h3 className=" my-auto font-weight-bold">Booking Details</h3>
									<Link to={`/appointment-booking`}>
										<button

											className="btn my-auto align-items-center Bechofy-btn AddNewBtn"
											style={{
												marginTop: '9px'
											}}
										>
											<span className="">
												<img src={backicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }} /> </span>
											Back to Booking List{" "}
										</button>
									</Link>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div>
					{this.state.bookingData.map((data) => (
						<table className="mx-5" style={{ width: "100%", borderCollapse: "collapse", marginTop: "15px" }}>
							<tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4", width: "30%" }}>
									<strong>Name:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_name}</td>
							</tr>
							<tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Gender:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_gender}</td>
							</tr>
							<tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Marital Status:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_maritalStatus}</td>
							</tr>
							{data.fld_dob !== null && <tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Date Of Birth:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_dob}</td>
							</tr>}
							{data.fld_time_of_birth !== null && <tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Time Of Birth:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_time_of_birth}</td>
							</tr>}
							{data.fld_birthPlace !== null && <tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Birth Place:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_birthPlace}</td>
							</tr>}
							{data.fld_area !== null && <tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Vastu Area:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_area}</td>
							</tr>}
							<tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Email:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_email}</td>
							</tr>
							<tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Mobile No.:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_mobile}</td>
							</tr>
							<tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Service Name:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_service}</td>
							</tr>
							<tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Appointment Date:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_date}</td>
							</tr>
							{data.fld_slot_duration != "" || data.fld_slot_duration != null && <tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Appointment Duration:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_slot_duration}</td>
							</tr>}
							{data.fld_time !== null && <tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Appointment Time:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_time}</td>
							</tr>}
							{data.fld_message == "" || data.fld_message == null && <tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Message:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_message}</td>
							</tr>}
							{data.fld_service == "Handwriting and Signature Analysis" &&
								<tr>
									<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
										<strong>Handwriting or Signature Image:</strong>
									</td>
									<td style={{ padding: "8px", border: "1px solid #ddd" }}>
										<img
											src={data.fld_image1}
											height="70px"
											alt="Signature"
										/>
									</td>
								</tr>
							}
							{data.fld_service == "Palmistry" &&
								<tr>
									<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
										<strong>Palmistry Image:</strong>
									</td>
									<td style={{ padding: "8px", border: "1px solid #ddd" }}>
										<img
											src={data.fld_image1}
											height="70px"
											alt="Signature"
										/>
										<img
											src={data.fld_image2}
											height="70px"
											alt="Signature"
										/>
									</td>
								</tr>
							}
							<tr>
								<td style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#f4f4f4" }}>
									<strong>Payment Done:</strong>
								</td>
								<td style={{ padding: "8px", border: "1px solid #ddd" }}>{data.fld_message}</td>
							</tr>
						</table>))}

				</div>
			</React.Fragment>
		);
	}
}
export default ViewAppointment;
