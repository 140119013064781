import Parser from "html-react-parser";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import addicon from "../../assets/images/dashboardimages/add.png";
import deleteicon from "../../assets/images/dashboardimages/delete.png";
import editicon from "../../assets/images/dashboardimages/edit.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import searchdata from "../../assets/images/search-engine.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
import "../Authentication/login.css";
const Skeletoncategorymaster = ({ cards }) => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between d-flex mb-0 pb-0">
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between py-0">
          <Col xs="12" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      {/* *********** Card *********** */}
      <Card>
        <CardBody className="pt-0">
          <Row>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="12">
                    <div className="d-flex mb-3">
                      <Skeleton className="py-1 px-5" /> /{" "}
                      <Skeleton className="py-1 px-5" />{" "}
                    </div>
                    <div className="imagecard">
                      <div className="form-group ">
                        <Skeleton
                          className="mx-0 mb-3 mt-2"
                          style={{ height: "20px", width: "200px" }}
                        />
                        <div className="div1">
                          <Skeleton
                            style={{ height: "200px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="12">
                    <div className="d-flex mb-3">
                      <Skeleton className="py-1 px-5" /> /{" "}
                      <Skeleton className="py-1 px-5" />{" "}
                    </div>
                    <div className="imagecard">
                      <div className="form-group ">
                        <Skeleton
                          className="mx-0 mb-3 mt-2"
                          style={{ height: "20px", width: "200px" }}
                        />
                        <div className="div1">
                          <Skeleton
                            style={{ height: "200px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="12">
                    <div className="d-flex mb-3">
                      <Skeleton className="py-1 px-5" /> /{" "}
                      <Skeleton className="py-1 px-5" />{" "}
                    </div>
                    <div className="imagecard">
                      <div className="form-group ">
                        <Skeleton
                          className="mx-0 mb-3 mt-2"
                          style={{ height: "20px", width: "200px" }}
                        />
                        <div className="div1">
                          <Skeleton
                            style={{ height: "200px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Card>
  );
};
class CoatingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceData: [],
      filteredServiceData: [],
      searchInput: "",
      count: "",
      loader: true,
    };
  }
  componentDidMount() {
    localStorage.removeItem("ServiceDetails")
    PostApiCall.postRequest(
      {
        whereClause: "",
        catCount: "*",
      },
      "GetServices"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          ServiceData: obj.data,
          count: obj.data.length,
          filteredServiceData: obj.data,
          loader: false,
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
    const searchedService = this.state.ServiceData.filter(item =>
      item.fld_service_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredServiceData: searchedService
    })

  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              )
                .replace("-", " ")
                .replace("_", " ")}
              urlPath={"/horoscope-management"}
            />
            {this.state.loader == true ? (
              <>
                <Skeletoncategorymaster />
              </>
            ) : (
              <Row>
                <Col xl="12">
                  <div className="overflow-hidden">
                    <div className="Bechofy-bg-soft-pink">
                      <Row>
                        <Col xs="6">
                          <div className="Bechofy-text p-3">
                            <h5 className="Bechofy-text">
                              {this.state.count == 0 ? (
                                "Category List"
                              ) : (
                                <>Service List [{this.state.count}]</>
                              )}
                            </h5>
                          </div>
                        </Col>
                        <Col xs="6">
                          <div className="Bechofy-text p-3">
                            <Link
                              to={`/add-service/create`}
                              className="align-items-center Bechofy-btn category-Add-btn categorybtnposition"
                            >
                              <span className="">
                                <img
                                  src={addicon}
                                  alt="block user"
                                  className="btnicons"
                                  style={{ marginRight: "5px" }}
                                ></img>{" "}
                              </span>
                              Add New Service{" "}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Card style={{ padding: "10px 20px" }}>
                      <div className="row my-1 ">
                        <div className="col-12">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <span className="">
                                  <img
                                    src={Searchicon}
                                    alt="block user"
                                    className="btnicons"
                                  ></img>
                                </span>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type service name you wish to search within the grid"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              onChange={(e) => this.seachBarHandler(e)}
                              value={this.state.searchInput}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <CardBody className="pt-0">
                        <Row>
                          {/* {this.state.ServiceData.filter((data) => {
                            let name = [
                              ...data?.fld_categoryname
                                ?.toLowerCase()
                                .matchAll(
                                  this.state.searchInput?.toLowerCase()
                                ),
                            ];
                            let status = [
                              ...data?.fld_showOnWebsite
                                ?.toLowerCase()
                                .matchAll(
                                  this.state.searchInput?.toLowerCase()
                                ),
                            ];
                            if (this.state.searchInput.length > 0 && name[0]) {
                              return data;
                            }
                            if (
                              this.state.searchInput.length > 0 &&
                              status[0]
                            ) {
                              return data;
                            }
                            if (this.state.searchInput.length == 0) {
                              return data;
                            } */}
                          {/* }) */}
                          {this.state.filteredServiceData?.map((data, i) => {
                            return (
                              <Col xs="12" md="12" lg="6" xl="4">
                                <CardBody className="categoryCard">
                                  <CardTitle className="mb-4">
                                    <nav
                                      aria-label="breadcrumb"
                                      className="px-1 py-1 m-0  justify-content-start"
                                      style={{ background: "none" }}
                                    >
                                      <ol
                                        className="breadcrumb p-0 mb-2"
                                        style={{
                                          background: "none",
                                          color: "#495057",
                                        }}
                                      >
                                        <li
                                          className="breadcrumb-item active"
                                          aria-current="page"
                                        >
                                          {data.fld_service_name}
                                        </li>
                                      </ol>
                                    </nav>
                                    <Row></Row>
                                  </CardTitle>
                                  <Row className="card-row">
                                    <Col sm="12">
                                      <div className="imagecard">
                                        <img
                                          src={data.fld_image}
                                          className="categoryimage categorymanagment-img-style"
                                        ></img>
                                      </div>
                                    </Col>
                                    <Col
                                      sm="12"
                                      className="my-3 card-content-overflow-subcategory"
                                    >
                                      <div className="">
                                        <p className="categorydesc px-0 subcategory-card-content">
                                          {" "}
                                          {data.fld_long_desc &&
                                            data.fld_long_desc !== "" ? (
                                            Parser(
                                              data.fld_long_desc
                                                .replace(/font-family/g, "")
                                                .replace(/<p>/g, "")
                                            )
                                          ) : (
                                            <div className="tex-center category-description-empty">
                                              <img src={searchdata} />
                                              <p>Details not available</p>
                                            </div>
                                          )}
                                        </p>
                                      </div>
                                    </Col>
                                    <hr />
                                  </Row>
                                  <Row>
                                    <Col sm="6">
                                      <p className="categorystatus">
                                        <b>Status</b> -{" "}
                                        <span
                                          style={{
                                            color:
                                              data.fld_status ==
                                                "Active" ||
                                                data.fld_status == "True"
                                                ? "green"
                                                : "red",
                                          }}
                                        >
                                          {data.fld_status}
                                        </span>
                                      </p>
                                    </Col>
                                    <Col sm="6">
                                      {/* <span className="btn deleteicnbtn">
                                        <span className="">
                                          <img
                                            src={deleteicon}
                                            alt="block user"
                                            className="btnicons"
                                          // onClick={() => {
                                          //   confirmAlert({
                                          //     title: "Confirm to Delete",
                                          //     message:
                                          //       "Are you sure you want to delete category?",
                                          //     buttons: [
                                          //       {
                                          //         label: "Yes",
                                          //         onClick: () => {
                                          //           PostApiCall.postRequest(
                                          //             {
                                          //               id: data.fld_categoryid,
                                          //               showOnWebsite:
                                          //                 "Disabled",
                                          //             },
                                          //             "Delete_Category"
                                          //           ).then((results) =>
                                          //             results
                                          //               .json()
                                          //               .then((obj) => {
                                          //                 if (
                                          //                   results.status ==
                                          //                     200 ||
                                          //                   results.status ==
                                          //                     201
                                          //                 ) {
                                          //                   Notiflix.Loading.Remove();
                                          //                   Notiflix.Notify.Success(
                                          //                     "Category successfully deleted."
                                          //                   );
                                          //                   window.location.reload();
                                          //                 } else {
                                          //                   Notiflix.Loading.Remove();
                                          //                   Notiflix.Notify.Failure(
                                          //                     "Something went wrong, try again later."
                                          //                   );
                                          //                 }
                                          //               })
                                          //           );
                                          //         },
                                          //       },
                                          //       {
                                          //         label: "No",
                                          //       },
                                          //     ],
                                          //   });
                                          // }}
                                          ></img>{" "}
                                        </span>
                                      </span> */}
                                      <Link
                                        to={`/add-service/update`}
                                        onClick={() => {
                                          localStorage.setItem(
                                            "ServiceDetails",
                                            JSON.stringify(data)
                                          );
                                        }}
                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                        style={{
                                          marginTop: "10px",
                                          color: "white",
                                        }}
                                      >
                                        <span className="">
                                          <img
                                            src={editicon}
                                            alt="block user"
                                            className="btnicons"
                                            style={{ marginRight: "5px" }}
                                          ></img>{" "}
                                        </span>
                                        Edit Service
                                      </Link>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Col>
                            );
                          })}
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default CoatingList;
