import imageCompression from "browser-image-compression";
import CKEditor from "ckeditor4-react";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import backicon from '../../assets/images/backicon.png';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
class categoryManagement extends Component {
  constructor(props) {
    super(props);
    const { action } = props.match.params;
    ////
    this.state = {
      imagePreviewUrl:
        "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
      ImageApiUrl: 'https://api.bechofy.in/AddImage',
      Status: "Yes",
      sectionwithimage: "No",
      ImageData: [],
      VerticalData: [],
      HoroscopeName: "",
      HoroscopeId: null,
      LuckyColor: "",
      ColorCode: "",
      LuckyNumber: "",
      Action: action,
      HoroscopeDesciption: "",
      Verticalname: "",
      originalImage: [],
      originalLink: "",
      clicked: false,
      uploadImage: false,
      ImageName: null,
      CategoryImage: null,
      hasSubcategory: "No",
      radioDisabled: false,
      showonHomePage: "No",
      horoscopeImage: null,
      fromDate: null,
      toDate: null,
    };
  }
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    var det = localStorage.getItem("HoroscopeDetails");
    var HoroscopeData = JSON.parse(det);
    if (HoroscopeData != null) {
      if (this.state.Action == "update") {
        this.setState({
          radioDisabled: true,
          HoroscopeName: HoroscopeData.fld_name,
          HoroscopeId: HoroscopeData.fld_id,
          ColorCode: HoroscopeData.fld_colorcode,
          LuckyColor: HoroscopeData.fld_color,
          LuckyNumber: HoroscopeData.fld_lucky_number,
          Status: HoroscopeData.fld_status == "Active" ? "Yes" : "No",
          fromDate: new Date(HoroscopeData.fld_fromdate),
          toDate: new Date(HoroscopeData.fld_todate),
        });
      }
      if (HoroscopeData.fld_image != null) {
        this.setState({
          imagePreviewUrl: HoroscopeData.fld_image,
          horoscopeImage: HoroscopeData.fld_image,
        });
      } else {
        this.setState({
          imagePreviewUrl:
            "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
        });
      }
      if (this.state.Action == "update") {
        new Promise((resolve, reject) => {
          setTimeout(resolve, 1000);
        }).then(() => {
          this.setState({
            HoroscopeDesciption: HoroscopeData.fld_long_desc,
            // TermsCondition:OfferData.fld_termscondition,
          });
        });
      }
    }
  }
  // ==Image Upload onChange Function=======
  photoUpload = async (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    await this.setState({
      imagePreviewUrl: URL.createObjectURL(imageFile),
      originalImage: imageFile,
      outputFileName: imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
      uploadImage: true,
    });
    this.onUploadImage();
  };
  onChangeDescription(text) {
    this.setState({
      HoroscopeDesciption: text.editor.getData(),
    });
  }
  onSaveData() {
    if (this.state.HoroscopeName == "") {
      Notiflix.Notify.Failure("Please enter horoscope name");
      return;
    }
    // if (this.state.toDate == null) {
    //   Notiflix.Notify.Failure("Please enter to date");
    //   return;
    // }
    if (this.state.LuckyColor == "") {
      Notiflix.Notify.Failure("Please enter lucky color");
      return;
    }
    if (this.state.ColorCode == "") {
      Notiflix.Notify.Failure("Please enter color code");
      return;
    }
    if (this.state.LuckyNumber == "") {
      Notiflix.Notify.Failure("Please enter lucky number");
      return;
    }
    if (this.state.imagePreviewUrl == null) {
      Notiflix.Notify.Failure("Please upload Horoscope image");
      return;
    }
    if (this.state.fromDate == null) {
      Notiflix.Notify.Failure("Please enter to date");
      return;
    }
    if (this.state.toDate == null) {
      Notiflix.Notify.Failure("Please enter to date");
      return;
    }
    if (this.state.HoroscopeDesciption == "") {
      Notiflix.Notify.Failure("Please enter horoscope description");
      return;
    }
    this.onPost();

  }
  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    if (this.state.horoscopeImage != this.state.imagePreviewUrl) {
      if (this.state.outputFileName != undefined) {
        PostApiCall.postRequest(
          {
            horoscopeid: this.state.HoroscopeId,
            // verticalid: this.state.Verticalname,
            name: this.state.HoroscopeName,
            shortdesc: null,
            longdesc: this.state.HoroscopeDesciption,
            icon: this.state.sectionwithimage,
            image: this.state.horoscopeImage,
            status: this.state.Status == "Yes" ? "Active" : "Inactive",
            color: this.state.LuckyColor,
            colorcode: this.state.ColorCode,
            luckynumber: this.state.LuckyNumber,
            fromdate: this.state.fromDate,
            todate: this.state.toDate,
          },
          "AddHoroscope"
        ).then((resultcategory) =>
          resultcategory.json().then((obj) => {
            if (resultcategory.status == 200 || resultcategory.status == 201) {
              // this.onPostAddCategoryImage(obj);
              //  //
              Notiflix.Loading.Remove();
              this.props.history.push("/horoscope-management")
              // window.location.href="/horoscope-management"
            } else {
              Notiflix.Loading.Remove();
              Notiflix.Notify.Failure(obj.data);
            }
          })
        );
      } else {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Failure("Please Upload Horoscope Image");
      }
    } else {
      this.onUpdateCategory();
    }
  };
  onUpdateCategory = () => {
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        horoscopeid: this.state.HoroscopeId,
        // verticalid: this.state.Verticalname,
        name: this.state.HoroscopeName,
        shortdesc: null,
        longdesc: this.state.HoroscopeDesciption,
        icon: null,
        image: this.state.horoscopeImage,
        status: this.state.Status == "Yes" ? "Active" : "Inactive",
        color: this.state.LuckyColor,
        colorcode: this.state.ColorCode,
        luckynumber: this.state.LuckyNumber,
        fromdate: this.state.fromDate,
        todate: this.state.toDate,
      },
      "AddHoroscope"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          // this.onPostAddCategoryImage(obj);
          //  //
          Notiflix.Loading.Remove();
          this.props.history.push("/horoscope-management")
          // window.location.href = "/horoscope-management"
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };
  async onUploadImage(obj) {
    Notiflix.Loading.Dots("Uploading Images...");
    //
    let response;
    if (this.state.outputFileName != undefined) {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(this.state.originalImage, options).then(
        (compressedFile) => {
          //
          //this.state.HoroscopeName.trim().replace(/\s/g, "-") +
          //"-" +
          //compressedFile.name)
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "horoscopeImages");
          form.append(
            "filename",
            this.state.HoroscopeName.trim().replace(/\s/g, "-") +
            "-" +
            compressedFile.name
          );
          response = fetch(this.state.ImageApiUrl, {
            method: "POST",
            body: form,
            headers: {
              "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
              "x-auth-token": JSON.parse(localStorage.getItem("access")),
              "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
            }
          })
            .then((response) => response.json()
              .then((data) => {
                if (response.status == 200 || response.status == 201) {
                  //
                  //
                  // this.setState({
                  // 	ImageName: data.Message.split(",")[2].split("=")[1].trim()
                  // })
                  this.setState({
                    horoscopeImage: data.image
                  })
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Success("Image successfully uploaded.");
                  // window.location.href = "/horoscope-management";
                } else {
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Failure("Image upload failed please tyr again or contact support.");
                }
              })
            );
          // //
        }
      );
    }
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    // //
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              breadcrumbItem1={
                this.state.HoroscopeId == null
                  ? "Add New Horoscope"
                  : "Update Horoscope"
              }
              urlPath={"/category-management"}
              urlPath1={
                this.state.HoroscopeId == null
                  ? "/add-horoscope/create"
                  : "/add-horoscope/update"
              }
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          {this.state.HoroscopeId == null ? (
                            <h5 className="Bechofy-text">Add New Horoscope</h5>
                          ) : (
                            <h5 className="Bechofy-text">Update Horoscope</h5>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <Link to={`/horoscope-management`}>
                          <button
                            // onClick={() => {
                            //   window.location.href = "/category-management";
                            // }}
                            className="btn align-items-center Bechofy-btn AddNewBtn"
                            style={{
                              marginTop: '9px'
                            }}
                          >
                            <span className="">
                              <img src={backicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }} /> </span>
                            Back to Horoscope List{" "}
                          </button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row  ">
                            <div className="col col-12">
                              <div className="row">



                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="HoroscopeName">
                                      Horoscope Name
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="HoroscopeName"
                                      className="form-control"
                                      value={this.state.HoroscopeName}
                                      onChange={(text) => {
                                        this.setState({
                                          HoroscopeName: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="LuckyColor">
                                      Lucky Color
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="LuckyColor"
                                      className="form-control"
                                      value={this.state.LuckyColor}
                                      onChange={(text) => {
                                        this.setState({
                                          LuckyColor: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="ColorCode">
                                      Color Code
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="ColorCode"
                                      className="form-control"
                                      value={this.state.ColorCode}
                                      onChange={(text) => {
                                        this.setState({
                                          ColorCode: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="LuckyNumber">
                                      Lucky Number
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="LuckyNumber"
                                      className="form-control"
                                      value={this.state.LuckyNumber}
                                      onChange={(text) => {
                                        this.setState({
                                          LuckyNumber: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                {/* show on website */}
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="HoroscopeName">
                                      Show On Website
                                      <span className="mandatory">*</span>
                                    </label>
                                    <br />
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="optradio"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Status == "Yes"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Status: "Yes",
                                          });
                                        }}
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      className="radio-inline"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="radio"
                                        name="optradio"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Status == "No"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Status: "No",
                                          });
                                        }}
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div>
                                {/* from and to date */}
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="HoroscopeName">
                                      To From Date
                                      <span className="mandatory">*</span>
                                    </label>
                                    <br />
                                    <div className="d-flex justify-content-between gap-5">
                                      <DatePicker selected={this.state.fromDate} onChange={(date) => {
                                        this.setState({
                                          fromDate: date
                                        })
                                      }} />
                                      <DatePicker selected={this.state.toDate} onChange={(date) => {
                                        this.setState({
                                          toDate: date
                                        })
                                      }} />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="sw-arrows-first-name">
                                      Description(maximum 500 Characters)
                                    </label>
                                    <div className="niceeditors">
                                      <CKEditor
                                        config={{
                                          extraPlugins:
                                            "justify,font,colorbutton",
                                        }}
                                        data={this.state.HoroscopeDesciption}
                                        onChange={this.onChangeDescription.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-1"></div>
                              <div className="col-md-11">
                                <label htmlFor="sw-arrows-first-name">
                                  Upload Horoscope Image (Size &lt; 100kb,
                                  500*500)<span className="mandatory">*</span>
                                </label>
                                <div className="div1">
                                  <ImgUpload
                                    onChange={this.photoUpload}
                                    src={this.state.imagePreviewUrl}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="py-1 my-1">
                    <Row>
                      <Col xs="12">
                        <div className="row">
                          <div className="col-3 offset-9">
                            <button
                              style={{ float: "right" }}
                              className="btn align-items-center Bechofy-btn "
                              onClick={this.onSaveData.bind(this)}
                            >
                              {this.state.HoroscopeId == null ? (
                                <span>Save Horoscope</span>
                              ) : (
                                <span>Update Horoscope</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {/*
                </Card>
                            </Col>
                               </Row> */}
                  {/* </Card> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default categoryManagement;
