import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Calendericon from '../../assets/images/dashboardimages/calendar.png';
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png';
import Filtericon from '../../assets/images/dashboardimages/filter.png';
import Refreshicon from '../../assets/images/dashboardimages/refresh.png';
import Searchicon from '../../assets/images/dashboardimages/search.png';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Dropdown } from "bootstrap";
class AppointmentBooking extends Component {
	constructor(props) {
		super(props);
		this.csvLink = React.createRef()
		this.state = {
			open: false,
			UserData: [],
			Id: "",
			searchInput: "",
			searchUser: "",
			FromDate: null,
			ToDate: null,
			bookingData: [],
			filteredData: [],
			CustomerData: [],
			SearchField: null,
			fileData: [],
			UploadPdfUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
			KeyLineId: null,
			KeyLineFile: [],
			sortedData: []
		};
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	componentDidMount() {
		localStorage.removeItem("AppointmentId")
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		this.getBookingList();
	}

	getBookingList = () => {
		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				catCount: "*",
				whereClause: "",
			},
			"GetBookedAppointments"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						bookingData: obj.data,
						filteredData: obj.data,
						sortedData: obj.data,
						fileData: obj.data,
						searchInput: ""
					});
					Notiflix.Loading.Remove();
				}
			}))
	}
	onPost = () => {
		const filteredByDate = this.state.bookingData.filter((item) => {
			const formattedFldDate = moment(item.fld_date, "DD/MM/YYYY");

			const fromDate = new Date(moment(this.state.FromDate).format("YYYY-MM-DD"));
			const toDate = new Date(moment(this.state.ToDate).format("YYYY-MM-DD"));
			const appointmentDate = new Date(formattedFldDate.format("YYYY-MM-DD"));

			return appointmentDate >= fromDate && appointmentDate <= toDate;
		});

		this.setState({
			filteredData: filteredByDate,
		});

	}

	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
		const searchedData = this.state.bookingData.filter(item => {
			return item.fld_service && item.fld_service.toLowerCase().includes(e.target.value.toLowerCase());
		});
		this.setState({
			filteredData: searchedData
		});
	};


	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "sn",
					sort: "asc",
					width: 150,
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 270,
				},
				{
					label: "Service",
					field: "service",
					sort: "asc",
					width: 200,
				},
				{
					label: "Email",
					field: "email",
					sort: "asc",
					width: 200,
				},
				{
					label: "Mobile Number",
					field: "mobile",
					sort: "asc",
					width: 100,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.filteredData.map((data, i) => {
				return {
					sn: (i + 1),
					name: (data.fld_name),
					service: (data.fld_service),
					email: (data.fld_email),
					mobile: (data.fld_mobile),
					action: <p>
						<span className="" style={{ cursor: "pointer" }}>
							<button className="btn align-items-center btn Bechofy-btn mr-3" onClick={() => {
								localStorage.setItem("AppointmentId", JSON.stringify(data.fld_id));
								window.location.href = "/view-appointment-booking";
							}}>view</button>

						</span>
					</p>
				}
			})
		}
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')} urlPath={"/order_list"} />
						<Row>
							<Col xl="12">
								<Card className="pagebackground">
									<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
										<div className="row my-1">
											<div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
												</div>
											</div>
										</div>
									</div>
									<Card style={{ padding: ' 0 20px' }}>
										<div className="row my-1 ">
											<div className="col-4">
												<div className="form-group ">
													<div className="input-group">
														<div className="input-group-prepend gridinput">
															<span className="input-group-text" id="basic-addon1">
																<span className=""><img src={Filtericon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text"
															value={this.state.SearchField}
															onChange={(text) => {
																this.setState({
																	SearchField: text.target.value
																})
															}}
															className="form-control gridinput" placeholder="Search either Customer Name / Order Status" aria-label="Search" aria-describedby="basic-addon1" />
													</div>
												</div>
											</div>
											<div className="col-1">
												<button
													onClick={() => {
														if (this.state.SearchField != null) {
															// this.onPostSearch()
														}
														else {
															Notiflix.Notify.failure('Please enter the name of Customer')
														}
													}}
													style={{
														marginTop: "15px",
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													Search
												</button>
											</div>
											<div className="col-2">
												<div className="form-group ">
													<DatePicker
														placeholderText="From Date"
														dateFormat="dd/MM/yyyy"
														selected={this.state.FromDate}
														onChange={(date) => {
															this.setState({
																FromDate: date
															})
														}}
														className="form-control gridinput"
														maxDate={new Date()}
													/>
													<span className="login-icon-change-pass" style={{ bottom: '30px' }}>
														<img src={Calendericon} alt="datepicker" className="btnicons"></img>
													</span>
												</div>
											</div>
											<div className="col-2">
												<div className="form-group">
													<DatePicker
														placeholderText="To Date"
														dateFormat="dd/MM/yyyy"
														selected={this.state.ToDate}
														onChange={(date) => {
															this.setState({
																ToDate: date
															})
														}}
														className="form-control gridinput"
														maxDate={new Date()}
													/>
													<span className="login-icon-change-pass" style={{ bottom: '30px' }}>
														<img src={Calendericon} alt="datepicker" className="btnicons"></img>
													</span>
												</div>
											</div>
											<div className="col-3">
												<button
													onClick={() => {
														if (this.state.FromDate != null || this.state.ToDate != null) {
															this.onPost()
														}
														else {
															Notiflix.Notify.failure('Please select from and to date.')
														}
													}}
													style={{
														marginTop: "15px",
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													Filter & Display
												</button>
												{/* <button
													onClick={() => {
														Notiflix.Loading.Dots("Please wait...");
														PostApiCall.postRequest(
															{
																WhereClause: '',
																RecordCount: '*'
															},
															"GetCustomerOrderDetails"
														).then((results) =>
															results.json().then((obj) => {
																if (results.status == 200 || results.status == 201) {
																	// //
																	this.setState({
																		OrderData: obj.data,
																		sortedData: obj.data,
																		fileData: obj.data,
																		searchInput: ""
																	});
																	Notiflix.Loading.Remove();
																}
															}))
													}}
													style={{
														marginTop: "15px",
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													List All Orders
												</button> */}
												<button className="btn align-items-center btn Bechofy-btn "
													style={{
														marginTop: "15px",
													}}
													onClick={() => {
														window.location.reload();
													}}
												>
													<span className=""><img src={Refreshicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
												</button>
											</div>
										</div>
									</Card>
									<Card >
										<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
											<div className="row my-1">
												<div className="col-4">
													<div className="form-group">
														<h4 className="FilterCard" style={{ marginTop: '10px' }}>Appointment Booking</h4>
													</div>
												</div>
											</div>
										</div>
										<CardBody className="pt-0">

											<Row>
												<MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
													<div className="input-group mb-3">
														<div className="input-group-prepend">
															<span className="input-group-text" id="basic-addon1">
																<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text" className="form-control" placeholder="Type the service name you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
															onChange={(e) => this.seachBarHandler(e)}
															value={this.state.searchInput}
														/>
													</div>
												</MDBCol>

												<Col md="12">
													<div className="d-flex justify-content-end "><select class="form-control w-auto" aria-label="Default select example" onChange={this.sortByPaymentStatus}>
														<option selected>All Appointments</option>
														<option value="approved">Approved</option>
														<option value="rejected">Rejected</option>
													</select>
													</div>

													{this.state.sortedData.length != 0 ?
														<MDBDataTable
															hover
															striped
															bordered
															data={data}
															searchTop={false}
															entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
														>
															<MDBTableHead columns={data.columns} />
															<MDBTableBody rows={data.rows} />
														</MDBDataTable>
														:
														<p>No Data Found</p>}
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AppointmentBooking;
