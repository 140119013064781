import imageCompression from "browser-image-compression";
import CKEditor from "ckeditor4-react";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import backicon from '../../assets/images/backicon.png';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
const IconUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload-icon" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img
        htmlFor="photo-upload-icon"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload-icon" type="file" onChange={onChange} />
  </label>
);
class categoryManagement extends Component {
  constructor(props) {
    super(props);
    const { action } = props.match.params;
    ////
    this.state = {
      imagePreviewUrl:
        "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
      iconPreviewUrl:
        "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
      ImageApiUrl: 'https://api.bechofy.in/AddImage',
      Status: "Yes",
      sectionwithimage: "No",
      ImageData: [],
      VerticalData: [],
      ServiceName: "",
      ServiceID: null,
      Action: action,
      CategoryDescription: "",
      longdesc: "",
      shortdesc: "",
      Verticalname: "",
      originalImage: [],
      originalLink: "",
      clicked: false,
      uploadImage: false,
      ImageName: null,
      CategoryImage: null,
      hasSubcategory: "No",
      radioDisabled: false,
      showonHomePage: "No",
      serviceImage: null,
      serviceIcon: null,
    };
  }
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticalData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
    var det = localStorage.getItem("ServiceDetails");
    var ServiceData = JSON.parse(det);
    if (ServiceData != null) {
      if (this.state.Action == "update") {
        this.setState({
          radioDisabled: true,
          ServiceName: ServiceData.fld_service_name,
          ServiceID: ServiceData.fld_id,
          Status: ServiceData.fld_status == "Active" ? "Yes" : "No",
        });
      }
      if (ServiceData.fld_image != null) {
        this.setState({
          imagePreviewUrl: ServiceData.fld_image,
          iconPreviewUrl: ServiceData.fld_icon,
          serviceImage: ServiceData.fld_image,
          serviceIcon: ServiceData.fld_icon,
        });
      } else {
        this.setState({
          imagePreviewUrl:
            "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
          iconPreviewUrl:
            "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
        });
      }
      if (this.state.Action == "update") {
        new Promise((resolve, reject) => {
          setTimeout(resolve, 1000);
        }).then(() => {
          this.setState({
            longdesc: ServiceData.fld_long_desc,
            shortdesc: ServiceData.fld_short_desc,
            // TermsCondition:OfferData.fld_termscondition,
          });
        });
      }
    }
  }
  // ==Image Upload onChange Function=======
  photoUpload = async (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    await this.setState({
      imagePreviewUrl: URL.createObjectURL(imageFile),
      originalImage: imageFile,
      outputFileName: imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
      uploadImage: true,
    });
    this.onUploadImage();
  };
  iconUpload = async (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    await this.setState({
      iconPreviewUrl: URL.createObjectURL(imageFile),
      originalImage: imageFile,
      outputFileName: imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
      uploadImage: true,
    });
    this.onUploadIcon();
  };
  onChangeDescription(text) {
    this.setState({
      shortdesc: text.editor.getData(),
    });
  }
  onChangeLongDescription(text) {
    this.setState({
      longdesc: text.editor.getData(),
    });
  }
  onSaveData() {
    if (this.state.ServiceName === "") {
      Notiflix.Notify.Failure("Please enter the Service name");
      return;
    } else if (this.state.serviceImage === "") {
      Notiflix.Notify.Failure("Please upload the Service image");
      return;
    } else if (this.state.serviceIcon === "") {
      Notiflix.Notify.Failure("Please upload the Service icon");
      return;
    }
    this.onPost()
  }
  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    if (this.state.serviceImage != this.state.imagePreviewUrl) {
      if (this.state.outputFileName != undefined) {
        PostApiCall.postRequest(
          {
            serviceid: this.state.ServiceID,
            // verticalid: this.state.Verticalname,
            servicename: this.state.ServiceName,
            shortdesc: this.state.shortdesc,
            longdesc: this.state.longdesc,
            icon: this.state.serviceIcon,
            image: this.state.serviceImage,
            status: this.state.Status == "Yes" ? "Active" : "Inactive",
            message: null
          },
          "AddService"
        ).then((resultcategory) =>
          resultcategory.json().then((obj) => {
            if (resultcategory.status == 200 || resultcategory.status == 201) {
              // this.onPostAddCategoryImage(obj);
              //  //
              Notiflix.Loading.Remove();
              this.props.history.push("/service-management")
            } else {
              Notiflix.Loading.Remove();
              Notiflix.Notify.Failure(obj.data);
            }
          })
        );
      } else {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Failure("Please Upload Service Image");
      }
    } else {
      this.onUpdateCategory();
    }
  };
  onUpdateCategory = () => {
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        serviceid: this.state.ServiceID,
        // verticalid: this.state.Verticalname,
        servicename: this.state.ServiceName,
        shortdesc: this.state.shortdesc,
        longdesc: this.state.longdesc,
        icon: this.state.serviceIcon,
        image: this.state.serviceImage,
        status: this.state.Status == "Yes" ? "Active" : "Inactive",
        message: null
      },
      "AddService"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          // this.onPostAddCategoryImage(obj);
          //  //
          Notiflix.Loading.Remove();
          this.props.history.push("/service-management")
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };
  // onPostAddCategoryImage(obj) {
  //   var login = localStorage.getItem("LoginDetail");
  //   var details = JSON.parse(login);
  //   if (this.state.CategoryImage != this.state.imagePreviewUrl) {
  //     PostApiCall.postRequest(
  //       {
  //         id: JSON.parse(JSON.stringify(obj.data[0])).ServiceId,
  //         image: 'https://store.bechofy.in/images/' +
  //           JSON.parse(localStorage.getItem("ClientDetails"))[0]
  //             .fld_dbschema +
  //           "/categoryImages/" +
  //           this.state.ServiceName.trim().replace(/\s/g, "-") +
  //           "-" +
  //           this.state.outputFileName.split('.').slice(0, -1).join('.') + '.webp',
  //         updatedon: moment().format("YYYY-MM-DD"),
  //         updatedby: details[0].fld_userid,
  //       },
  //       "UpdateCategoryImage"
  //     ).then((results1) =>
  //       results1.json().then((obj1) => {
  //         if (results1.status == 200 || results1.status == 201) {
  //           // //
  //           // Notiflix.Loading.Remove();
  //           Notiflix.Notify.Success(
  //             "Category successfully added. Image upload in process"
  //           );
  //           this.onUploadImage(obj);
  //         }
  //       })
  //     );
  //   } else {
  //     PostApiCall.postRequest(
  //       {
  //         id: JSON.parse(JSON.stringify(obj.data[0])).ServiceId,
  //         image: this.state.imagePreviewUrl,
  //         updatedon: moment().format("YYYY-MM-DD"),
  //         updatedby: details[0].fld_userid,
  //       },
  //       "UpdateCategoryImage"
  //     ).then((results1) =>
  //       results1.json().then((obj1) => {
  //         if (results1.status == 200 || results1.status == 201) {
  //           // //
  //           Notiflix.Loading.Remove();
  //           Notiflix.Notify.Success("Category successfully updated.");
  //           window.location.href = "/category-management";
  //         } else {
  //           Notiflix.Loading.Remove();
  //           Notiflix.Notify.Failure(obj1.data);
  //         }
  //       })
  //     );
  //   }
  // }
  async onUploadImage(obj) {
    Notiflix.Loading.Dots("Uploading Images...");
    //
    let response;
    if (this.state.outputFileName != undefined) {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(this.state.originalImage, options).then(
        (compressedFile) => {
          //
          //this.state.ServiceName.trim().replace(/\s/g, "-") +
          //"-" +
          //compressedFile.name)
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "horoscopeImages");
          form.append(
            "filename",
            this.state.ServiceName.trim().replace(/\s/g, "-") +
            "-" +
            compressedFile.name
          );
          response = fetch(this.state.ImageApiUrl, {
            method: "POST",
            body: form,
            headers: {
              "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
              "x-auth-token": JSON.parse(localStorage.getItem("access")),
              "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
            }
          })
            .then((response) => response.json()
              .then((data) => {
                if (response.status == 200 || response.status == 201) {
                  //
                  //
                  // this.setState({
                  // 	ImageName: data.Message.split(",")[2].split("=")[1].trim()
                  // })
                  this.setState({
                    serviceImage: data.image
                  })
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Success("Image successfully uploaded.");
                  // window.location.href = "/horoscope-management";
                } else {
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Failure("Image upload failed please tyr again or contact support.");
                }
              })
            );
          // //
        }
      );
    }
  }
  async onUploadIcon(obj) {
    Notiflix.Loading.Dots("Uploading Icon...");
    //
    let response;
    if (this.state.outputFileName != undefined) {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(this.state.originalImage, options).then(
        (compressedFile) => {
          //
          //this.state.ServiceName.trim().replace(/\s/g, "-") +
          //"-" +
          //compressedFile.name)
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "horoscopeImages");
          form.append(
            "filename",
            this.state.ServiceName.trim().replace(/\s/g, "-") +
            "-" +
            compressedFile.name
          );
          response = fetch(this.state.ImageApiUrl, {
            method: "POST",
            body: form,
            headers: {
              "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
              "x-auth-token": JSON.parse(localStorage.getItem("access")),
              "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
            }
          })
            .then((response) => response.json()
              .then((data) => {
                if (response.status == 200 || response.status == 201) {
                  //
                  //
                  // this.setState({
                  // 	ImageName: data.Message.split(",")[2].split("=")[1].trim()
                  // })
                  this.setState({
                    serviceIcon: data.image
                  })
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Success("Icon successfully uploaded.");
                  // window.location.href = "/horoscope-management";
                } else {
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Failure("Image upload failed please tyr again or contact support.");
                }
              })
            );
          // //
        }
      );
    }
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    // //
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              breadcrumbItem1={"Service Management"
              }
              urlPath={"/service-management"}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          {this.state.ServiceID == null ? (
                            <h5 className="Bechofy-text">Add New Service</h5>
                          ) : (
                            <h5 className="Bechofy-text">Update Category</h5>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <Link to={`/service-management`}>
                          <button
                            className="btn align-items-center Bechofy-btn AddNewBtn"
                            style={{
                              marginTop: '9px'
                            }}
                          >
                            <span className="">
                              <img src={backicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }} /> </span>
                            Back to Service List{" "}
                          </button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row  ">
                            <div className="col col-12">
                              <div className="row">
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="serviceName">
                                      Service Name
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="serviceName"
                                      className="form-control"
                                      value={this.state.ServiceName}
                                      onChange={(text) => {
                                        this.setState({
                                          ServiceName: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>



                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="HoroscopeName">
                                      Show On Website
                                      <span className="mandatory">*</span>
                                    </label>
                                    <br />
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="optradio"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Status == "Yes"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Status: "Yes",
                                          });
                                        }}
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      className="radio-inline"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="radio"
                                        name="optradio"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Status == "No"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Status: "No",
                                          });
                                        }}
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="sw-arrows-first-name">
                                      Short Description(maximum 500 Characters)
                                    </label>
                                    <div className="niceeditors">
                                      <CKEditor
                                        config={{
                                          extraPlugins:
                                            "justify,font,colorbutton",
                                        }}
                                        data={this.state.shortdesc}
                                        onChange={this.onChangeDescription.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="sw-arrows-first-name">
                                      Description(maximum 3000 Characters)
                                    </label>
                                    <div className="niceeditors">
                                      <CKEditor
                                        config={{
                                          extraPlugins:
                                            "justify,font,colorbutton",
                                        }}
                                        data={this.state.longdesc}
                                        onChange={this.onChangeLongDescription.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-1"></div>
                              <div className="col-md-4">
                                <label htmlFor="sw-arrows-first-name">
                                  Upload Service Image (Size &lt; 100kb,
                                  500*500)<span className="mandatory">*</span>
                                </label>
                                <div className="div1">
                                  <ImgUpload
                                    onChange={this.photoUpload}
                                    src={this.state.imagePreviewUrl}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="sw-arrows-first-name">
                                  Upload Service Icon (Size &lt; 100kb,
                                  500*500)<span className="mandatory">*</span>
                                </label>
                                <div className="div1">
                                  <IconUpload
                                    onChange={this.iconUpload}
                                    src={this.state.iconPreviewUrl}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="py-1 my-1">
                    <Row>
                      <Col xs="12">
                        <div className="row">
                          <div className="col-3 offset-9">
                            <button
                              style={{ float: "right" }}
                              className="btn align-items-center Bechofy-btn "
                              onClick={this.onSaveData.bind(this)}
                            >
                              {this.state.ServiceID == null ? (
                                <span>Save Horoscope</span>
                              ) : (
                                <span>Update Horoscope</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {/*
                </Card>
                            </Col>
                               </Row> */}
                  {/* </Card> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default categoryManagement;
